import React from 'react';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Start = ({handleChange, handleStart, openAiApiKey}) => {
  return (
    <div>
      <h1>Welcome to EasyTranscribe.online</h1>
      <h4>Your Free and Easy Solution for Audio Transcription</h4>
      <p> Whether you need to transcribe interviews, meetings, or any other audio content, we've got you covered. This 100% free app runs completely on your browser. No subscription required or account to register.</p>
      <Form.Label>Please provide an OpenAI API key</Form.Label>
      <Form.Control type="text" placeholder="Your OpenAI API Key" onChange={handleChange} value={openAiApiKey ? openAiApiKey : ''} />
      <Form.Text className="text-muted">
        This API Key is not sent to any server
      </Form.Text>
      <div>
        <Button variant="success" onClick={handleStart}>Start</Button>
      </div>

      <h2 className="mt-4">How it works</h2>
      <Row>
        <Col className="my-2">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Upload Your Audio</Card.Title>
              <Card.Text>
                Easily select your audio files. Your files stay securely client-side, ensuring your privacy.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-2">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Automatic Splitting</Card.Title>
              <Card.Text>
                Our app automatically divides your audio into convenient 60-second clips or less, saving you time and effort.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-2">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Playback and Transcribe</Card.Title>
              <Card.Text>
                Play back any of the generated clips and transcribe some or all of your clips as you please.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      <h2 className="mt-3">Why Choose EasyTranscribe.online?</h2>
      <Row>
        <Col className="my-2">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>100% Free Tool</Card.Title>
              <Card.Text>
                While you still need an API key for OpenAI, this tool is completely free to use.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-2">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>No Account</Card.Title>
              <Card.Text>
                This app is built to run 100% on the browser. Nothing is sent to another server besides OpenAI. No sign in required.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col className="my-2">
          <Card style={{ width: '18rem' }}>
            <Card.Body>
              <Card.Title>Easy to Use</Card.Title>
              <Card.Text>
              EasyTranscribe.online is designed with simplicity in mind. No technical expertise required—transcribe with ease. Select your file. Transcribe. Done.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <h2>How do I get an OpenAI API key?</h2>
      <p><a href="https://platform.openai.com/docs/quickstart/account-setup" target="_blank" rel="noreferrer">Official instructions from OpenAI</a></p>
      <p>First, create an <a href="https://platform.openai.com/signup" target="_blank" rel="noreferrer">OpenAI account</a> or <a href="https://platform.openai.com/login" target="_blank" rel="noreferrer">sign in</a>. Next, navigate to the <a href="https://platform.openai.com/account/api-keys" target="_blank" rel="noreferrer">API key page</a> and "Create new secret key", optionally naming the key. Finally, simply copy and paste your API key into the form above and click 'Start'.</p>

      <h3 className="mt-4">Feedback and Suggestions</h3>
      <div>
        <p>Have any feedback? Suggestions? Please let me know!</p>
        <Button href="https://brendan-inc.fibery.io/@public/forms/1L2s4NwD" target="_blank">Feedback</Button>
      </div>

    </div>
  );
};

export default Start;
