import React from 'react';
import AudioClip from './AudioClip';

const AudioClips = ({audioClips, transcriptions, handleTranscribeClip, handlePlayAudioClip, isTranscribingAll}) => {
  return (
    <div>
      {audioClips.length > 0 && (
        <div>
          {audioClips.map((clip, idx) =>{
            return (
              <AudioClip 
                key={idx} 
                audioClip={clip} 
                transcription={transcriptions[idx]} 
                handleTranscribeClip={handleTranscribeClip} 
                handlePlayAudioClip={handlePlayAudioClip} 
                index={idx}
                isTranscribingAll={isTranscribingAll}
              />
            )
            })
          }
        </div>
      )}
    </div>
  );
};

export default AudioClips;
