import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import CopyButton from './CopyButton';

const AudioClip = ({audioClip, transcription, handleTranscribeClip, handlePlayAudioClip, index, isTranscribingAll}) => {
  const [isTranscribing, setIsTranscribing] = useState(false);

  const isTranscribed = transcription !== '';

  const handleTranscribeClick = async (clip, clipIdx) => {
    setIsTranscribing(true);
    await handleTranscribeClip(clip, clipIdx);
    setIsTranscribing(false);
  }

  const transcribeBtnText = () => {
    if (isTranscribed) {
      return 'Transcribed'
    } else if (isTranscribing || isTranscribingAll) {
      return 'Transcribing'
    } else {
      return 'Transcribe'
    }
  }

  return (
    <Card className="mb-2">
      <Card.Header>Clip {index + 1} - {audioClip.duration} seconds </Card.Header>
      <Card.Body>
        <Button className="me-3" onClick={() => handlePlayAudioClip(audioClip)}>Play</Button>
        <Button variant="success" onClick={() => handleTranscribeClick(audioClip, index)} disabled={isTranscribed || isTranscribing || isTranscribingAll}>
          {(isTranscribing || isTranscribingAll) && (<Spinner animation="border" variant="light" size="sm" className="me-2" />)}
          {transcribeBtnText()}
        </Button>
        <Card.Title className="mt-2">Transcription</Card.Title>
        <Card.Text>
          {transcription}
        </Card.Text>
        <Card.Text>
          <CopyButton textToCopy={transcription} />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default AudioClip;
