import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';

import checkImg from '../assets/check.png'
import clipboardImg from '../assets/clipboard.png'

const CopyButton = ({textToCopy}) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = (textToCopy) => {
    // Show the feedback element
    setIsCopied(true);

    // Hide the feedback element after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
    navigator.clipboard.writeText(textToCopy)
  };

  return (
    <Button variant="outline-dark" onClick={() => copyToClipboard(textToCopy)}>
      <img src={isCopied ? checkImg : clipboardImg} alt="clipboard" height="20" className="me-2" />
      <span>{isCopied ? "copied!" : "copy"}</span>
    </Button>
  )
}

export default CopyButton;