import React, { useState, useRef, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import AudioClips from './AudioClips';
import CopyButton from './CopyButton';
import ErrorMsg from './ErrorMsg';

import {convertAudioBufferToBlob, createAudioClips} from '../utils/audio'
import {maskAPIKey, isAPIKey} from '../utils/utils'

const Transcribe = ({openAiAPIKey, handleRestart}) => {
  const [fileName, setFileName] = useState(null);
  const [creatingClips, setCreatingClips] = useState(false);
  const [audioClips, setAudioClips] = useState([]);
  const [transcriptions, setTranscriptions] = useState([]);
  const [prevTranscriptions, setPrevTranscriptions] = useState([]);
  const [isTranscribingAll, setIsTranscribingAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const audioRef = useRef(null);
  // clip length in seconds
  const clipLimit = 60;

  const allTranscribed = () => {
    return transcriptions.every((t) => t !== '')
  }

  const arrayNotEmpty = (arr) => {
    return !arr.every((a) => a === '')
  }

  const handleTranscribe = async (audioBuffer, clipIndex) => {
    try {
      setErrorMessage(null);

      const audioBlob = convertAudioBufferToBlob(audioBuffer)

      // Create a FormData object and append the file and other form data
      const formData = new FormData();
      formData.append('file', audioBlob, { filename: 'test.wav' });
      formData.append('model', 'whisper-1');

      const response = await fetch('https://api.openai.com/v1/audio/transcriptions', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${openAiAPIKey}`,
        },
        body: formData,
      });
    
      if (!response.ok) {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    
      const data = await response.json();
      console.log("Transcription:", data.text);
    
      setTranscriptions(prev => {
        const newArray = [...prev];
        newArray[clipIndex] = data.text;
        return newArray;
      });
    } catch (error) {
      console.error(error);
      setErrorMessage(error);
    }
  }

  // FOR TESTING - simulate API call
  // function delay(ms) {
  //   return new Promise(resolve => setTimeout(resolve, ms));
  // }

  // const handleTranscribe = async (audioBuffer, clipIndex) => {
  //   setErrorMessage(null)
  //   await delay(2000);

  //   // setErrorMessage('Something went wrong!')

  //   setTranscriptions(prev => {
  //     const newArray = [...prev];
  //     newArray[clipIndex] = 'this is a fake transcription';
  //     return newArray;
  //   });
  // }

  const handleTranscribeAll = async () => {
    setIsTranscribingAll(true);

    const transcribePromises = [];

    // only transcribe those that haven't been already
    audioClips.forEach((clip, idx) => {
      if (transcriptions[idx] === '') {
        transcribePromises.push(handleTranscribe(clip, idx))
      }
    })

    await Promise.all(transcribePromises);

    setIsTranscribingAll(false);
  }

  const handleFileUpload = async (event) => {
    setCreatingClips(true);
    const input = event.target;

    if (input.files && input.files.length > 0) {
      const audioFile = input.files[0];

      const allowedExtensions = ['mp3', 'mp4', 'mpeg', 'mpga', 'm4a', 'wav', 'webm'];
      const fileExtension = audioFile.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension)) {
        setFileName(audioFile.name);

        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const audioBuffer = await readAudioFile(audioFile, audioContext);

        const clips = createAudioClips(audioBuffer, clipLimit);
        setAudioClips(clips);
        // set transctiptions array to same length
        const arrayLength = clips.length;
        const arrayOfEmptyStrings = Array.from({ length: arrayLength }, () => '');
        setTranscriptions(arrayOfEmptyStrings);
      } else {
        alert('Invalid file type. Please upload a valid audio file.');
      }
    }
    setCreatingClips(false);
  };

  const readAudioFile = async (file, audioContext) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const arrayBuffer = reader.result;
          const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
          resolve(audioBuffer);
        } catch (error) {
          reject(error);
        }
      };

      reader.readAsArrayBuffer(file);
    });
  };

  const playAudioClip = (audioBuffer) => {
    const wav = convertAudioBufferToBlob(audioBuffer)
    const audioUrl = URL.createObjectURL(wav);

    audioRef.current.src = audioUrl;
    audioRef.current.play();
  }

  // TODO - would be nice to not overwrite everything from a previous transcription...
  // Save to local storage
  useEffect(() => {
    if (fileName && transcriptions.length > 0 && arrayNotEmpty(transcriptions)) {
      localStorage.setItem(fileName, JSON.stringify(transcriptions));
      setPrevTranscriptions(transcriptions);
    }
  }, [transcriptions]);

  // check if the file as previous transcriptions stored
  useEffect(() => {
    const prevTrans = localStorage.getItem(fileName);
    if (prevTrans) {
      setPrevTranscriptions(JSON.parse(prevTrans));
    } else {
      setPrevTranscriptions([])
    }
  }, [fileName]);

  return (
    <div>
      <Alert variant="info">
        Transcribing takes at least as much time as the clip duration. Please be patient.
      </Alert>

      {errorMessage && (<ErrorMsg errorMessage={errorMessage} />)}

      <h1>Transcribe Your Audio File</h1>
      {!isAPIKey(openAiAPIKey) && (
        <Alert variant="warning">
          Are you sure this is an OpenAI API key? Double check and make sure.
        </Alert>
        )}
      <p>Your API key {maskAPIKey(openAiAPIKey)} <Button className="ms-2" onClick={handleRestart}>Edit</Button></p>
      
      <Form.Group controlId="formFileLg" className="mb-3">
        <Form.Label>Please select an audio file you would like to transcribe</Form.Label>
        <Form.Control type="file" accept="audio/*" onChange={handleFileUpload} disabled={creatingClips} />
      </Form.Group>

      {creatingClips && (<div className="text-center"><Spinner variant="primary" animation="grow" className="m-3" /><p>Splitting audio file and preparing for transcription...</p></div>)}

      {(!creatingClips && audioClips.length > 0) && (
        <>
          <div>
            <audio ref={audioRef} controls></audio>
          </div>

          <p className="mt-3">
            <Button variant="success" onClick={handleTranscribeAll} disabled={isTranscribingAll || allTranscribed()}>
              {isTranscribingAll && (<Spinner animation="border" variant="light" size="sm" className="me-2" />)}
              {(isTranscribingAll ? 'Transcribing All' : (allTranscribed() ? 'All Transcribed' : 'Transcribe All'))}
            </Button>
          </p>

          <Tabs
            defaultActiveKey={(prevTranscriptions.length > 0 && arrayNotEmpty(prevTranscriptions)) ? "prevTranscription" : "clips"}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="clips" title="Audio Clips">
              <AudioClips audioClips={audioClips} transcriptions={transcriptions} handlePlayAudioClip={playAudioClip} handleTranscribeClip={handleTranscribe} isTranscribingAll={isTranscribingAll} />
            </Tab>
            <Tab eventKey="transcription" title="Full Transcription">
              <div className="mb-3">
                <CopyButton textToCopy={transcriptions.join('\n')} />
              </div>
              {transcriptions.map((t, idx) => <p key={idx}>{t}</p>)}
              <div className="mt-3">
                <CopyButton textToCopy={transcriptions.join('\n')} />
              </div>
            </Tab>
            {(prevTranscriptions.length > 0 && arrayNotEmpty(prevTranscriptions)) && (
              <Tab eventKey="prevTranscription" title="Previous Transcription">
                <Alert variant="primary">
                  It looks like you might have transcribed this file before. Here is a previous transcription.
                  (This is stored on your browser's local storage.)
                </Alert>
                <div className="mb-3">
                  <CopyButton textToCopy={prevTranscriptions.join('\n')} />
                </div>
                {prevTranscriptions.map((t, idx) => <p key={idx}>{t}</p>)}
                <div className="mt-3">
                  <CopyButton textToCopy={prevTranscriptions.join('\n')} />
                </div>
              </Tab>
            )}
          </Tabs>
        </>
      )}
    </div>
  );
};

export default Transcribe;
