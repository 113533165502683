import React, { useState } from 'react';
import Start from './components/Start';
import Transcribe from './components/Transcribe';
import Container from 'react-bootstrap/Container';
import Header from './components/Header';

const App = () => {
  // TODO
  // +/- a few seconds to each clip to provide some overlap in case words get cut off
  // save this stuff to the local storage?
  // provide instructions or a link for how to get an Open AI API key?

  const [openAiAPIKey, setOpenAiAPIKey] = useState(null);
  const [start, setStart] = useState(false);

  const handleAPIKey = (event) => {
    setOpenAiAPIKey(event.target.value);
  }

  const handleRestart = (event) => {
    setStart(false);
  }

  const handleStart = () => {
    if (openAiAPIKey && openAiAPIKey !== '') {
      setStart(true);
    }
  }

  return (
    <>
      <Header />
      <Container className="mt-4 mb-5">
        {openAiAPIKey && start ? (
          <Transcribe openAiAPIKey={openAiAPIKey} handleRestart={handleRestart} />
        ) : (
          <Start handleChange={handleAPIKey} handleStart={handleStart} openAiApiKey={openAiAPIKey} />
        )}
      </Container>
    </>
  );
};

export default App;
