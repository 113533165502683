export const maskAPIKey = (inputString) => {
  // Get the last 3 characters of the string
  const lastThreeCharacters = inputString.slice(-3);

  // Add 5 "*" characters to the start
  const transformedString = "*********" + lastThreeCharacters;

  return transformedString;
}

export const isAPIKey = (inputString) => {
  const regex = /^[a-zA-Z]{2}-[a-zA-Z0-9]{48}$/;

  // Example usage:
  // const testString = 'sk-asdfasdfasdfasdfasdfasdfasdfasdfasdfasdfadfadfad';
  return regex.test(inputString);
}
